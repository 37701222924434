<script lang="ts" setup>
import { FormKit } from '@formkit/vue'
import type { NoteMessage } from './composables/types'

interface Props {
  fieldId?: string
  isCreatingNotes?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  fieldId: undefined,
  isCreatingNotes: false,
})

defineExpose({
  clearInput,
})

const emit = defineEmits<{
  (e: 'submit', payload: NoteMessage): void
}>()

const form = ref<typeof FormKit>(null!)

// submit form
const onSubmitForm = async (data: { note: string }): Promise<void> => {
  if (!data.note) return

  emit('submit', {
    message: data.note,
    subject: '',
  })
}

function clearInput() {
  form.value.node.reset()
}
</script>

<template>
  <div
    ref="newComment"
    class="pb-safe-bottom absolute bottom-0 right-0 w-full rounded-t-lg bg-neutral-100 bg-opacity-80 text-xl dark:bg-gray-900"
  >
    <FormKit
      ref="form"
      v-slot="{ state }"
      type="form"
      form-class="flex px-4 py-6 w-full flex-col"
      :actions="false"
      :disabled="isCreatingNotes"
      dirty-behavior="compare"
      @submit="onSubmitForm"
    >
      <h3 class="mb-2 text-base font-medium">
        <span v-if="fieldId">
          Add a note for the field
          <span
            class="text-primary font-bold underline decoration-dotted underline-offset-4"
            >{{ fieldId }}</span
          >
        </span>
        <span v-else> Add a note </span>
      </h3>
      <div class="inline-flex w-full items-end gap-2">
        <FormKit
          id="addNewNote"
          type="textarea"
          name="note"
          outer-class="grow"
          :inner-class="isCreatingNotes ? 'opacity-80 ' : undefined"
          dirty-behavior="compare"
          :disabled="isCreatingNotes"
          auto-height
          :max-auto-height="200"
          data-1p-ignore
          @keyup.prevent.ctrl.enter.stop="form?.node.submit()"
          @keyup.prevent.meta.enter.stop="form?.node.submit()"
        />

        <Button
          type="submit"
          :loading="isCreatingNotes"
          :disabled="!state.dirty || isCreatingNotes"
          color="primary"
          class="mb-1 flex h-10 w-10 cursor-pointer"
          :class="{
            '': !isCreatingNotes,
          }"
          icon="send"
          @click.prevent="form?.node.submit()"
        >
          <span>
            <Spinner
              v-if="isCreatingNotes"
              class="-ml-1 -mt-1 text-black"
              size="xs"
            />
          </span>
        </Button>
      </div>
    </FormKit>
  </div>
</template>
